


















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ItemDetailsViewer extends Vue {
  item = null

  mounted() {
    this.$root.$on('setItemOnItemDetailsViewer', item => {
      this.item = item
    })
  }
}
